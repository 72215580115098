import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { truncateString } from '../../utility';
import { getUserNameAttributes } from '../utility/Map';

type TableData= {
    id : string, 
    name:string,
    createdBy:string,
    createdDate:Date
}

type SampleTableProps = {
    sampleData : TableData[]
    handleLoad : (id:string)=>void,
    handleClose: () => void,
    handleDelete: (id: string ) => void,
    activeMapId: string | null
}

export const MONTHS = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

export default function SampleTable(props:SampleTableProps) {
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [ user, setUser ] = React.useState<any>(null);
    const {sampleData, handleLoad, handleClose, handleDelete } = props

    const date_format = (timestamp: number | string) => {
        const dateObject = new Date(timestamp)
        
        let formatted_day: any = dateObject.getDate();
        if(formatted_day < 10) formatted_day = '0' + formatted_day
        const humanDateFormat = `${formatted_day} ${MONTHS[dateObject.getMonth()]} ${dateObject.getFullYear()}`
        return humanDateFormat;
    }


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const visibleRows = React.useMemo(
        () =>
            sampleData?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [ page, rowsPerPage, sampleData],
    );

    React.useEffect(()=>{
      setPage(0)
    },[sampleData]);

    React.useEffect(() => {
      const getUser = async() => {
        const user = await getUserNameAttributes();
        setUser(user);
      }

      getUser();
    }, [])
  
  if(sampleData.length === 0) {
    return (
      <Box sx={{ minWidth: 650 }}>
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
         
          <Typography color={"grey"} mt={2} textAlign={"center"}>
            No Map Data Found...
          </Typography>

          <Button 
            onClick={() => {
              //@TODO: Fix this hacky work around to triggering add button
              const add: any = document.querySelector('.add-data-button')
              add?.click();
              handleClose();
            }}
            style={{ backgroundColor: '#A0A7B4', color: 'white', marginTop: 20 }}>
            Add Map Data
          </Button>
        </Box>
      </Box>
    )
  }
  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
    <TableContainer component={Paper}>
      <Table size='small' sx={{ minWidth: 1050 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* <TableCell sx={{fontWeight:'bold'}}>ID</TableCell> */}
            <TableCell sx={{fontWeight:'bold'}}>Name</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Description</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Created By</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Created Date</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Last Update</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
         
          {visibleRows.map((row:any) => (
            <TableRow
              selected={(row?.client_data_id === props.activeMapId)}
              key={row?.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, ':hover': { backgroundColor: '#d3d3d3', cursor: 'pointer' } }}
            >
              <TableCell component="th" scope="row">
                <Tooltip title={row?.name}><Typography>{truncateString(row?.name, 50) || '---'}</Typography></Tooltip>
              </TableCell>
              <TableCell><Tooltip title={row?.description}><Typography>{truncateString(row?.description, 100)}</Typography></Tooltip></TableCell>
              <TableCell>{row?.username}</TableCell>
              <TableCell>{date_format(row.created_at)}</TableCell>
              <TableCell>{date_format(row.updated_at)}</TableCell>
              <TableCell >
                <Box display={"flex"} flexDirection={"row"}>
                    <Tooltip title="Load Map Data">
                      <IconButton onClick={()=> { handleLoad(row?.client_data_id); handleClose()} } size="small" sx={{
                        backgroundColor: '#A0A7B4', 
                        color: 'white', 
                        textTransform: 'capitalize !important',
                        ":hover": {
                          bgcolor: "#818897"
                        }}}>
                          <CloudDownloadIcon style={{ fontSize: 16 }} />
                      </IconButton>
                    </Tooltip>
                  {/* @TODO: Add flow to ask user instead of straight delete */}
                  <Tooltip title="Delete Map Data">
                    
                    <IconButton disabled={!(user?.sub === row?.user_id)} onClick={()=> { handleDelete(row?.client_data_id); handleClose()} } size="small" sx={{
                      backgroundColor: '#A0A7B4', 
                      marginLeft: 2,
                      color: 'white', 
                      textTransform: 'capitalize !important',
                      ":hover": {
                        bgcolor: "#818897"
                      }}}>
                        <DeleteIcon style={{ fontSize: 16 }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={sampleData?.length ? sampleData.length : 0 }
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>
  );
}
