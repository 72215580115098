//@ts-nocheck
import geoViewport from "@mapbox/geo-viewport";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Button, IconButton, Tooltip } from "@mui/material";
import KeplerGl from "kepler.gl";
import { ActionTypes, addDataToMap, resetMapConfig } from "kepler.gl/actions";
import keplerGlReducer from "kepler.gl/reducers";
import KeplerGlSchema from 'kepler.gl/schemas';
import React, { useEffect, useState } from "react";
import { taskMiddleware } from "react-palm/tasks";
import { Provider, connect, useDispatch } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { handleActions } from "redux-actions";
import Loading from "../components/Loading";
import Logout from "../components/Logout";
import { ToggleButton } from "../components/ToggleButton";
import { getUserToken } from '../components/utility/Map';
import { AlertType, useNotification } from '../context/Notifications';
import { AuthProvider } from "../contexts/Auth";

const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const URL = process.env?.REACT_APP_URL || 'http://localhost'

interface IMapData {
    lonMax: number,
    lonMin: number,
    latMin: number,
    latMax: number,
    zoom: number
}

export const SaveButton = (props) => {
    return (
        <div style={{position: 'absolute', bottom: 50, right: 20}}>
            <Button onClick={props.handleSave} size="small" sx={{
                backgroundColor: '#6A7485', 
                color: 'white', 
                width:'140px',
                textTransform: 'capitalize !important',
                ":hover": {
                    bgcolor: "#818897"
                }}} startIcon={<SaveOutlinedIcon/>}>
                    Save Changes
                </Button>
        </div>)
}
const Map = ({ mapState }: IMapData) => {
    const [ activeClientDataId, setActiveClientId ] = useState(null);
    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ hasClientData, setHasClientData ] = React.useState(false);
    const [ mapRef, setMapRef ] = React.useState(null);
    const [advancedMap, setAdvancedMap] = React.useState(false);
    const dispatch = useDispatch();
    const { showAlert } = useNotification()

   
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdvancedMap(event.target.checked);    
    };

    useEffect(()=>{
        let readOnlyStatus = true
        let geocoderStatus = true
        if(advancedMap){
            readOnlyStatus = false
            geocoderStatus = false
        }

        dispatch(
            addDataToMap({
                options : {
                    readOnly : readOnlyStatus
                },
                config :{
                    visState :{
                        interactionConfig: {
                            geocoder: {
                                enabled: geocoderStatus,
                            }
                        },
                    }
                }
            })
        )
    }, [advancedMap]);

    const postData = async (data) => {
        const currentPath = window.location.href;
        const bodyData =  { url: encodeURIComponent(currentPath), mapState: data?.mapState, visState: data?.visState };
        
        try {
            const token = await getUserToken();
            const response = await fetch(`${URL}/data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(bodyData)
            });
            
            if (!response.ok) {
                showAlert(AlertType.Error , "Error saving client data")
            }
        } catch (error) {
            showAlert(AlertType.Error , "Error saving client data")
        }
    }

   

    const handleSave = async () => {
        const configToSave = KeplerGlSchema.getConfigToSave(store.getState()?.keplerGl?.map)
        const dataToSave = KeplerGlSchema.getDatasetToSave(store.getState()?.keplerGl?.map);
        
        if(configToSave && dataToSave) {
            try {
                setIsLoading(true);
                await postData({
                    mapState: dataToSave, 
                    visState: configToSave
                });
                showAlert(AlertType.Success, "Saved Client Data");
            } catch (e) {
                showAlert(AlertType.Error , "Error saving client data")
            } finally {
                setIsLoading(false);
            }
        } else {
            showAlert(AlertType.Info, "No Data to save")
        }
    }

    const fetchData = async() => {
        try {
            setIsLoading(true)
            const token = await getUserToken();
            const currentPath = window.location.href;
            const data = await fetch(`${URL}/client_data?q=${encodeURIComponent(currentPath)}`, {
                method: 'GET',
                headers: {
                'Authorization': `Bearer ${token}`
                }
            });
            // Check status 
            if(data.status === 404) {
                return null;
            }
            const state = await data.json();
            if(state?.client_data_id) {
                setActiveClientId(state?.client_data_id)
            }
            const mapToLoad = KeplerGlSchema.load(state?.mapState, state?.visualState);

            // Switch to advance mode view
            setAdvancedMap(true);
            await dispatch(addDataToMap({
                options : {
                    readOnly : false
                },
                config :{
                    visState :{
                        interactionConfig: {
                            geocoder: {
                                enabled: true,
                            }
                        },
                    }
                }
            }));
            
            await dispatch(addDataToMap(mapToLoad))
            showAlert(AlertType.Success, "Loaded data successfully")
        } catch(error) {
            showAlert(AlertType.Error, "Error fetching data")
        } finally {
            setIsLoading(false);
        }
    }

    const hasClientDataCheck = async() => {
        const currentPath = window.location.href;
        const token = await getUserToken();
        
        //@TODO: use head instead of a get to check if record exists
        const data = await fetch(`${URL}/client_data_exists?q=${encodeURIComponent(currentPath)}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        (data.status === 404) ? setHasClientData(false): fetchData();
    }

    const deleteSaveData = async () => {
        try {
            const token = await getUserToken();
            const response = await fetch(`${URL}/client_data/${activeClientDataId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
              });
            
            dispatch(resetMapConfig())
              if (response.ok) {
                showAlert(AlertType.Success, "Successfully deleted client data")
              } else {
                const errorData = await response.json();
                showAlert(AlertType.Error, errorData?.detail || "Error deleting data")
              } 
        } catch (error) {
            showAlert(AlertType.Error, "Error deleting data");
        }
    }

    useEffect(() => {
        hasClientDataCheck();
    }, []);

    return (
        <div style={{ backgroundColor: "#29323C", position: "fixed" }}>
            {isLoading && <Loading open={isLoading}/>}
            <KeplerGl
                id="map"
                mapboxApiAccessToken={TOKEN}
                width={window.innerWidth}
                height={window.innerHeight}
                hideSidePanel={false}
                config={{}}
                getMapboxRef={(ref) => {
                    const map = ref?.getMap();
                    setMapRef(map);
                }}
            />
            {
                hasClientData && (
                    <div style={{position: 'absolute', bottom: 130, right: 20 }}>
                        <Tooltip title="Load Save Data">
                            <IconButton 
                                style={{ backgroundColor: '#3a414c', color: '#A0A7B4', borderRadius: 0 }}
                                onClick={fetchData}>
                                <CloudDownloadIcon style={{ fontSize: '16px'}} />
                            </IconButton>
                        </Tooltip>
                        {
                            activeClientDataId && (
                                <Tooltip title="Delete Saved Data">
                                    <IconButton 
                                        style={{ backgroundColor: '#3a414c', color: '#A0A7B4', marginLeft: 10, borderRadius: 0 }}
                                        onClick={deleteSaveData}>
                                        <DeleteIcon style={{ fontSize: '16px'}}  />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                    </div>
                    
                   
                )
            }
            <ToggleButton
                handleChange={handleChange}
                checked={advancedMap}
            />
            <div style={{position: 'absolute', bottom: 180, right: 20 }}>
                <Logout />
            </div>
            {advancedMap &&
                <SaveButton
                    handleSave={handleSave}
                />
            }
        </div>
    );
};

let timerId;

const appReducer = handleActions(
    {
        "MAP_VALUES": (state, action) => {
            return {
                ...state,
                mval: action.payload
            }
        },
        // listen on kepler.gl map update action to store a copy of viewport in app state
        [ActionTypes.UPDATE_MAP]: (state, action) => {
            const mapState = action.payload.payload;
            const viewport = [mapState.longitude, mapState.latitude];
            const zoom = mapState.zoom;
            const dimensions = [mapState.width, mapState.height];
            const tileSize = 512;
            let [lonMin, latMin, lonMax, latMax] = geoViewport.bounds(
                viewport,
                zoom,
                dimensions,
                tileSize
            );
            if(timerId){
                clearTimeout(timerId)
            }
            timerId = setTimeout(async() => {
                if(lonMin){
                  state.mval?.setIsLoading(true)
                }
            }, 500)

            return {
                ...state,
                mapData: {
                    lonMax,
                    lonMin,
                    latMin,
                    latMax,
                    width: mapState.width,
                    height: mapState.height,
                    zoom
                },
                viewport: action.payload,
            };
        },
    },
    {}
);

const mapStateToProps = state => ({
    mapState: state
});

const ConnectedMap = connect(mapStateToProps)(Map);

const customizedKeplerGlReducer = keplerGlReducer.initialState({
    uiState: {
        currentModal: null,
    },
    mapStyle:{
        styleType:'light',
    }
});


const reducers = combineReducers({
    app: appReducer,
    keplerGl: customizedKeplerGlReducer
});

const store = createStore(reducers, {}, applyMiddleware(taskMiddleware));

const ProvidedMap = () => {
    return (
        <AuthProvider>
            <Provider store={store}>
                <ConnectedMap />
            </Provider>
        </AuthProvider>
       
    );
};

export default ProvidedMap;
