import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';

const Logout = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = React.useState(false);
    
    const handleLogout = async () => {
        setIsLoading(true);
        try {
            await Auth.signOut();
            navigate("/")
            console.log('Logout successful!');
        } catch (error) {
            console.log('Error signing out:', error);
        } finally {
            setIsLoading(false)
        }
    };

    return (
        <>
            <Loading open={isLoading} />
            <Tooltip title="Logout">
                <IconButton  style={{ backgroundColor: '#3a414c', color: '#A0A7B4' ,borderRadius: 0 }} onClick={() => handleLogout()}>
                    <LogoutIcon style={{ fontSize: '16px'}} />
                </IconButton>
            </Tooltip>
           
        </>
       
    )
}

export default Logout;