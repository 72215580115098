import CloseIcon from '@mui/icons-material/Close';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Modal, TextField, Typography } from "@mui/material";
import React from 'react';
import { IClientData } from '../../pages/kepler-raster';
import { getUserNameAttributes } from '../utility/Map';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
 
export const SaveButton = (props: { handleSave: (name: string, description: string, overwrite: boolean) => void, selectedMap: null | IClientData}) => {
    const [ isOpen, setIsOpen ] = React.useState(false);
    const [ name, setName ] = React.useState('');
    const [ description, setDescription ] = React.useState('');
    const [ isOwner, setIsOwner ] = React.useState(false); // Is Openned map created by the same user
    const [ overwrite, setOverWrite ] = React.useState(false); 

    const ownerCheck = async() => {
        const user = await getUserNameAttributes();
        if(user?.sub === props.selectedMap?.user_id) {
            setIsOwner(true);
            setOverWrite(true);
        } else {
            setIsOwner(false);
            setOverWrite(false);
        }
    }

    React.useEffect(() => {
        if(isOpen) {
            ownerCheck();
        } 
    }, [isOpen]);
    
    React.useEffect(() => {
        if(props?.selectedMap) {
            setName(props?.selectedMap?.name);
            setDescription(props?.selectedMap?.description)
        } 
    }, [props?.selectedMap])
    
    
    return (
        <>
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={() => { props.handleSave(name, description, overwrite); setIsOpen(false) }}>
                    <Box  sx={style}>
                        <IconButton onClick={() => setIsOpen(false)} style={{
                            backgroundColor: 'white',
                            position: 'fixed',
                            top: -50,
                            right: -50
                        }}>
                            <CloseIcon />
                        </IconButton>
                        <Box>
                            <Typography variant='subtitle1' align="center">Save Current Map</Typography>
                        </Box>
                        <TextField 
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value as any)}
                            type='text'
                            required 
                            sx={{ color: '#A0A7B4', marginRight: 2, mt: 2 }} 
                            size="small" 
                            id={"name"} 
                            label={"Name"} 
                            variant="outlined" 
                        />
                        <TextField 
                            fullWidth
                            multiline
                            value={description}
                            onChange={(e) => setDescription(e.target.value as any)}
                            type='text'
                            rows={4}
                            sx={{ color: '#A0A7B4', marginRight: 2, mt: 2 }} 
                            size="small" 
                            id={"description"} 
                            label={"Description"} 
                            variant="outlined" 
                        />
                        {
                            props?.selectedMap && (
                                <>
                                    {
                                        isOwner ? (
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox onChange={(e) => {
                                                    setOverWrite(e.target.checked) 
                                                }}  checked={overwrite} />} label="Overwrite Existing Map" />
                                            </FormGroup> 
                                        ) : null
                                    }
                                </>
                            )
                        }
                        <Button 
                            type='submit'
                            sx={{
                            backgroundColor: '#6A7485', 
                            color: 'white', 
                            mt: 2,
                            textTransform: 'capitalize !important',
                            ":hover": {
                                bgcolor: "#818897"
                            }
                        }} fullWidth >
                            Save Changes
                        </Button>
                    </Box>
                   
                </form>
            </Modal>
            <div style={{position: 'absolute', bottom: 20, right: 20}}>
                <Button  onClick={() => setIsOpen(true)} size="small" sx={{
                    backgroundColor: '#6A7485', 
                    color: 'white', 
                    width:'140px',
                    textTransform: 'capitalize !important',
                    ":hover": {
                        bgcolor: "#818897"
                    }}} startIcon={<SaveOutlinedIcon/>}>
                        Save Changes
                </Button>
             </div>
        </>
    )   
}

export default SaveButton;