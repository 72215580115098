import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SampleTable from './SampleTable';
import { TextField } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



type TableData= {
    id : string, 
    name:string,
    createdBy:string,
    createdDate:Date
}

type SampleModalProps = {
    open :  boolean,
    handleClose: (event : React.MouseEvent) => void,
    sampleData:TableData[],
    handleLoad : (id:string)=>void
    handleSearch: (event:React.ChangeEvent<HTMLInputElement>)=>void
}

export default function SampleModal(props:SampleModalProps) {

 const {open, handleClose, sampleData, handleLoad, handleSearch} = props


  return (
    <div>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
           <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Table title
          </Typography>
          <TextField size='small' placeholder='Search in ID, Name, Created By' onChange={handleSearch}></TextField>
          </Box>
          <SampleTable 
            sampleData={sampleData}
            handleLoad={handleLoad}/>
        </Box>
      </Modal>
    </div>
  );
}