//@ts-nocheck
import geoViewport from "@mapbox/geo-viewport";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from "@mui/material";
import KeplerGl from "kepler.gl";
import { ActionTypes, addDataToMap, resetMapConfig } from "kepler.gl/actions";
import keplerGlReducer from "kepler.gl/reducers";
import KeplerGlSchema from 'kepler.gl/schemas';
import React, { useEffect } from "react";
import { taskMiddleware } from "react-palm/tasks";
import { Provider, connect, useDispatch } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { handleActions } from "redux-actions";
import Loading from "../components/Loading";
import Logout from "../components/Logout";
import SaveButton from "../components/SaveChanges";
import SavedMaps from '../components/SavedMaps';
import { ToggleButton } from "../components/ToggleButton";
import { generateCoordinates, getUserToken } from '../components/utility/Map';
import { AlertType, useNotification } from '../context/Notifications';
import useDebounce from '../hooks/debounce';

const WAIT_TIME = 2000; // wait to seconds before issuing api request
const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const URL = process.env?.REACT_APP_URL || 'http://localhost'

interface IMapData {
    lonMax: number,
    lonMin: number,
    latMin: number,
    latMax: number,
    zoom: number
}

export interface IClientData {
    client_data_id: string,
    name: string,
    description: string,
    user_id: string,
    username: string,
    mapState?: any, 
    visualState?: any, 
    created_at: string,
    update_at: string // @TODO: Update for actual timestamp dates
}
  

const Map = ({ mapState }: IMapData) => {
    const [ activeClientDataId, setActiveClientId ] = React.useState(null);
    const [ hasClientData, setHasClientData ] = React.useState(false);
    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ mapRef, setMapRef ] = React.useState(null);
    const debouncedValue = useDebounce(mapState, WAIT_TIME);
    const [advancedMap, setAdvancedMap] = React.useState(process.env?.REACT_APP_DEFAULT_MAP_TO_ADVANCED?.toLowerCase() ==='true');
    const dispatch = useDispatch();
    const { showAlert } = useNotification();
    const [ selectedMap, setSelectedMap ] = React.useState<IClientData>(null);
    const [ dataLoaded, setDataLoaded ] = React.useState(false);

    useEffect(() => {
        if(!advancedMap){
            let mapData = debouncedValue?.app?.mapData
            if(mapRef && mapData) {
                const coordinates = generateCoordinates(mapData.lonMax, mapData?.lonMin, mapData?.latMax, mapData?.latMin);
                const PATH = `geometries/raster_multilayer`;
                renderRasher(mapRef, `${URL}/${PATH}?min_lon=${mapData?.lonMin}&max_lon=${mapData?.lonMax}&min_lat=${mapData?.latMin}&max_lat=${mapData?.latMax}&height=${mapData?.height}&width=${mapData?.width}`, coordinates)
            }
        }else{
            // setIsLoading(false)
        }

    }, [debouncedValue?.app?.mapData, advancedMap])

    const passValuesToReducer = () => ({
        type: "MAP_VALUES",
        payload: {
            setIsLoading,
            advancedMap
        },
      })

    useEffect(()=>{
        dispatch(passValuesToReducer())
    },[advancedMap])
    

    const overlayRasher = async (map: any, image: string, coordinates: number[][]) => {
        const token = await getUserToken();
        let response = await fetch(image, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}` //TODO: Please can we make this more generic for all calls
            }
        });

        let blob = await response?.blob();
        
        let objectURL = window?.URL?.createObjectURL(blob); 
       
        if(map.loaded()) {
            if(map.getSource('image-source')) {
                await map.removeLayer('raster-layer');
                await map.removeSource('image-source');
                await map.addSource('image-source', {
                    type: 'image',
                    url: objectURL,
                    coordinates: coordinates
                });
            } else {
                await map.addSource('image-source', {
                    type: 'image',
                    url: objectURL,
                    coordinates: coordinates
                });
            }

            if(map.getLayer('raster-layer')) {
                // update layer
                await map.removeLayer('raster-layer');
                await map.addLayer({
                    id: 'raster-layer',
                    type: 'raster',
                    source: 'image-source',
                    paint: {
                        'raster-fade-duration': 0
                    }
                });
            } else {
                await map.addLayer({
                    id: 'raster-layer',
                    type: 'raster',
                    source: 'image-source',
                    paint: {
                        'raster-fade-duration': 0
                    }
                });
            }
        }

    }

    const renderRasher = async (mapRef: any, image: string, coordinates: number[][]) => {
        setIsLoading(true)
        await overlayRasher(mapRef, image, coordinates);
        setIsLoading(false)

    }

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdvancedMap(event.target.checked);    
        if(mapRef.getLayer('raster-layer')) {
            await mapRef.removeLayer('raster-layer');
        }
        if(mapRef.getSource('image-source')) {
            await mapRef.removeSource('image-source');
        }
    };

    useEffect(()=>{
        let readOnlyStatus = true
        let geocoderStatus = true
        if(advancedMap){
            readOnlyStatus = false
        }

        dispatch(
            addDataToMap({
                options : {
                    readOnly : readOnlyStatus
                },
                config :{
                    visState :{
                        interactionConfig: {
                            geocoder: {
                                enabled: geocoderStatus,
                            }
                        },
                    },
                    mapStyle:{
                        styleType:'light',
                    }
                },

            })
        )
    }, [advancedMap]);

    const postData = async (data) => {
       
        const bodyData =  {  mapState: data?.mapState, visState: data?.visState, ...data };
        
        try {
            const token = await getUserToken();
            const response = await fetch(`${URL}/data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(bodyData)
            });
            
            if (!response.ok) {
                showAlert(AlertType.Error , "Error saving client data")
            }
        } catch (error) {
            showAlert(AlertType.Error , "Error saving client data")
        }
    }

    const putData = async (data) => {
        const bodyData = {  mapState: data?.mapState, visState: data?.visState, ...data };


        try {
            const token = await getUserToken();
            const response = await fetch(`${URL}/data/${data?.client_data_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(bodyData)
            });
            
            if (!response.ok) {
                showAlert(AlertType.Error , "Error saving client data")
            }
        } catch (error) {
            showAlert(AlertType.Error , "Error saving client data")
        }
    }
   

    const handleSave = async (name: string, description: string, overwrite: boolean = false) => {
        const configToSave = KeplerGlSchema.getConfigToSave(store.getState()?.keplerGl?.map)
        const dataToSave = KeplerGlSchema.getDatasetToSave(store.getState()?.keplerGl?.map);
        
        if(configToSave && dataToSave.length !== 0) {
            try {
                setIsLoading(true);
                if(overwrite) {
                    await putData({
                        mapState: dataToSave, 
                        visState: configToSave,
                        name,
                        description,
                        client_data_id: selectedMap?.client_data_id
                    })
                } else {
                    await postData({
                        mapState: dataToSave, 
                        visState: configToSave,
                        name,
                        description
                    });
                }
                
                showAlert(AlertType.Success, "Saved Client Data");
            } catch (e) {
                showAlert(AlertType.Error , "Error saving client data")
            } finally {
                setIsLoading(false);
            }
        } else {
            showAlert(AlertType.Error, "No data to save. Please upload you're data first!")
        }
    }

    const fetchData = async(id: string) => {
        try {
            setIsLoading(true)
            const token = await getUserToken();
            const data = await fetch(`${URL}/client_data/${id}`, {
                method: 'GET',
                headers: {
                'Authorization': `Bearer ${token}`
                }
            });
            // Check status 
            if(data.status === 404) {
                return null;
            }
            const state = await data.json();
            if(state?.client_data_id) {
                setActiveClientId(state?.client_data_id);
                setSelectedMap(state);
            }
            const mapToLoad = KeplerGlSchema.load(state?.mapState, state?.visualState);

            // Switch to advance mode view
            setAdvancedMap(true);
            await dispatch(addDataToMap({
                options : {
                    readOnly : false
                },
                config :{
                    visState :{
                        interactionConfig: {
                            geocoder: {
                                enabled: true,
                            }
                        },
                    }
                }
            }));
            
            await dispatch(addDataToMap(mapToLoad));   
            showAlert(AlertType.Success, "Loaded data successfully")
        } catch(error) {
            showAlert(AlertType.Error, "Error fetching data")
        } finally {
            setDataLoaded(true);
            setIsLoading(false);
        }
    }

    const deleteSaveData = async (client_id: string) => {
        setIsLoading(true);
        try {
            const token = await getUserToken();
            const response = await fetch(`${URL}/client_data/${client_id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
              });
            
            dispatch(resetMapConfig())
              if (response.status === 204) {
                showAlert(AlertType.Success, "Successfully deleted client data")
              } else {
                const errorData = await response.json();
                showAlert(AlertType.Error, errorData?.detail || "Error deleting data")
              } 
        } catch (error) {
            console.log(error);
            showAlert(AlertType.Error, "Error deleting data");
        } finally {
            setIsLoading(false);
        }
    }

    const enableGeoEncoder = async() => {
        await dispatch(addDataToMap({
            config :{
                visState :{
                    interactionConfig: {
                        geocoder: {
                            enabled: true,
                        }
                    },
                }
            }
        }));
    }
    
    useEffect(() => {
        enableGeoEncoder();
    }, []);


    return (
        <div style={{ backgroundColor: "#29323C", position: "fixed" }}>
            {isLoading && <Loading open={isLoading}/>}
            <KeplerGl
                id="map"
                mapboxApiAccessToken={TOKEN}
                width={window.innerWidth}
                height={window.innerHeight}
                hideSidePanel={false}
                config={{}}
                getMapboxRef={(ref) => {
                    const map = ref?.getMap();
                    setMapRef(map);
                }}
            />
            {/* Saved maps button */}
            <SavedMaps activeMapId={selectedMap?.client_data_id} handleDelete={deleteSaveData} handleLoad={fetchData} />
            {
                hasClientData && (
                    <div style={{position: 'absolute', bottom: 300, right: 20 }}>
                        <Tooltip title="Load Save Data">
                            <IconButton 
                                style={{ backgroundColor: '#3a414c', color: '#A0A7B4', borderRadius: 0 }}
                                onClick={fetchData}>
                                <CloudDownloadIcon style={{ fontSize: '16px'}} />
                            </IconButton>
                        </Tooltip>
                        {
                            activeClientDataId && (
                                <Tooltip title="Delete Saved Data">
                                    <IconButton 
                                        style={{ backgroundColor: '#3a414c', color: '#A0A7B4', marginLeft: 10, borderRadius: 0 }}
                                        onClick={deleteSaveData}>
                                        <DeleteIcon style={{ fontSize: '16px'}}  />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                    </div>
                    
                   
                )
            }
            {process.env?.REACT_APP_DISPLAY_SIMPLE_ADVANCED_TOGGLE?.toLowerCase() === 'true' &&
                <ToggleButton
                    handleChange={handleChange}
                    checked={advancedMap}
                />
            }
            <div style={{position: 'absolute', bottom: 180, right: 20 }}>
                <Logout />
            </div>
            {advancedMap &&
                <SaveButton
                    selectedMap={selectedMap}
                    handleSave={handleSave}
                />
            }
        </div>
    );
};

let timerId;

const appReducer = handleActions(
    {
        "MAP_VALUES": (state, action) => {
            return {
                ...state,
                mval: action.payload
            }
        },
        // listen on kepler.gl map update action to store a copy of viewport in app state
        [ActionTypes.UPDATE_MAP]: (state, action) => {
            const mapState = action.payload.payload;
            const viewport = [mapState.longitude, mapState.latitude];
            const zoom = mapState.zoom;
            const dimensions = [mapState.width, mapState.height];
            const tileSize = 512;
            let [lonMin, latMin, lonMax, latMax] = geoViewport.bounds(
                viewport,
                zoom,
                dimensions,
                tileSize
            );
            
                if(timerId){
                    clearTimeout(timerId)
                }
                timerId = setTimeout(async() => {
                    if(lonMin){
                        if(!state?.mval.advancedMap) {
                            state.mval?.setIsLoading(true)
                        }
                    }
                }, 500)
            

            return {
                ...state,
                mapData: {
                    lonMax,
                    lonMin,
                    latMin,
                    latMax,
                    width: mapState.width,
                    height: mapState.height,
                    zoom
                },
                viewport: action.payload,
            };
        },
    },
    {}
);

const mapStateToProps = state => ({
    mapState: state
});

const ConnectedMap = connect(mapStateToProps)(Map);

const customizedKeplerGlReducer = keplerGlReducer.initialState({
    uiState: {
        currentModal: null,
    },
    mapStyle:{
        styleType:'light',
    }
});

const reducers = combineReducers({
    app: appReducer,
    keplerGl: customizedKeplerGlReducer
});

const store = createStore(reducers, {}, applyMiddleware(taskMiddleware));

const ProvidedMap = () => {
    return (
        <Provider store={store}>
            <ConnectedMap />
        </Provider>
    );
};

export default ProvidedMap;